import React, { Fragment, useEffect } from "react"
import Layout from "../retech/components/layout/Layout"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import moment from "moment"
import Media from "../retech/components/MediaCenter/Index"
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

var showdown = require("showdown")
var converter = new showdown.Converter()

export default function MediaCenterPage({ data }) {
  function makeHtml(txt) {
    return converter.makeHtml(txt)
  }

  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const {
    CTALink,
    RelatedArticleBottom,
    Date,
    DetailDescriptionsection1,
    DetailImage,
    DetailSubTitle,
    Quote,
    RelatedArticleTop,
    Title,
    DetailDescriptionsection9,
  } = data.newsJson

  useEffect(() => console.log("Quote", Quote), [Quote])
  return (
    <Layout>
      <Helmet>
        <title>Retech | {Title}</title>

        <meta name="description" content={DetailDescriptionsection1} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={DetailDescriptionsection1} />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={Title} />
        <meta
          property="twitter:description"
          content={DetailDescriptionsection1}
        />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <div className="container">
        <div className="col-md-12 py-5 pl-xs-0 pr-xs-0 pt-xs-0">
          <div className="row">
            <div className="col-md-6 pt-5 social media-social">
              <p>Share:</p>
              <div className="Demo__some-network mb-1">
                <LinkedinShareButton
                  url={url}
                  quote="{title}"
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={72} bgstyle="#000" />
                </LinkedinShareButton>
              </div>
              <div className="Demo__some-network mb-1">
                <FacebookShareButton
                  url={url}
                  quote="{title}"
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={72} bgstyle="#000" />
                </FacebookShareButton>
              </div>

              <div className="Demo__some-network mb-1">
                <TwitterShareButton
                  url={url}
                  quote="{title}"
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={72} bgstyle="#000" />
                </TwitterShareButton>
              </div>
            </div>
            <div className="col-md-12 border-bottom text-center pl-xs-0 pr-xs-0">
              <h2 className="font-weight-bold my-4">{Title}</h2>
              <p className="product-par filter-name">
                <small className="text-muted">
                  {moment(Date).format("MMM DD,YYYY")}{" "}
                </small>{" "}
              </p>
              <p className="font-weight-bold mb-4">{DetailSubTitle}</p>
              <img
                src={DetailImage}
                alt="background media"
                className="w-100 bg-media"
                height="423px"
              />
            </div>
            <div className="col-md-12 mt-5">
              {DetailDescriptionsection9 !== undefined &&
                DetailDescriptionsection9 !== null &&
                DetailDescriptionsection9 !== "" && (
                  <div
                    className="SectionHtml"
                    dangerouslySetInnerHTML={{
                      __html: makeHtml(DetailDescriptionsection9),
                    }}
                  />
                )}
            </div>
            {/*<div className="col-md-4 mt-5 d-none d-sm-block">
                            <h5 className="font-weight-bold">Related Articles</h5>

                            {RelatedArticleTop?.map((node, index) => {
                                return (
                                    <>
                                        <div className="col-md-12">
                                            <img src={node.Image} alt="News" className="w-100 right-category" />
                                            <div className="parallelogram my-3">
                                                <Link to={"/mediacenters/" + node.CTALink} className="text-dark font-weight-bold">
                                                    {node.Category}
                                                </Link>
                                            </div>
                                            <Link to={"/mediacenters/" + node.CTALink} className="text-dark">
                                                <h3 className="font-weight-bold">{node.Title}</h3>  </Link>
                                        </div>
                                    </>
                                )
                            })}

                        </div>
                    */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12 border-bottom">
          <div className="row">
            <div className="col-xxl-5 col-lg-7 col-md-7">
              <h4 className="mb-4 font-weight-bolder">Related Articles</h4>
            </div>
          </div>
        </div>
        <div className="col-md-12 py-5 Media ">
          <Media />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ProjectDetails1($CTALink: String) {
    newsJson(CTALink: { eq: $CTALink }) {
      CTALink
      Date
      DetailImage
      DetailSubTitle
      Title
      DetailDescriptionsection9
    }
  }
`
